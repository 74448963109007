<template>
  <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide><img src="../assets/slider/orwell-1.jpg" /></swiper-slide>
    <swiper-slide><img src="../assets/slider/orwell-2.jpg" /></swiper-slide>
    <swiper-slide><img src="../assets/slider/orwell-3.jpg" /></swiper-slide>
    <swiper-slide><img src="../assets/slider/orwell-4.jpg" /></swiper-slide>
    <div class="swiper-pagination" slot="b"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  }
}
</script>

<style>
html,
body {
  position: relative;
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img{
  max-width: 100%;
  height: auto;
}
</style>
