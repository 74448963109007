








import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue' // @ is an alias to /src
import Swiper from '@/components/Swiper.vue' // @ is an alias to /src

@Component({
  components: {
    Header,
    Swiper
  }
})
export default class Home extends Vue {}
